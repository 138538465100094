import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() isMobile = false;
  @Output() toggleSideNav = new EventEmitter<void>();

  constructor(private authService: AuthService, private navService: NavService, private router: Router) {}

  logout() {
    this.authService.logout();
  }

  onToggleSideNav() {
    this.toggleSideNav.emit();
    this.navService.toggle();
  }
}
