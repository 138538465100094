import { Component, Input, SimpleChanges, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatDrawer } from '@angular/material/sidenav';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent {
  @ViewChild('drawer') drawer!: MatDrawer;
  @Input() toggleSideNav: boolean = false;
  @Input() isMobile = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isMobile && this.drawer.opened) {
          this.drawer.close();
        }
      });
  }

  logout() {
    this.authService.logout();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toggleSideNav && !changes.toggleSideNav.firstChange) {
      if (this.drawer) {
        this.drawer.toggle();
      }
    }
  }

  onNavClick(): void {
    if (this.isMobile && this.drawer.opened) {
      this.drawer.close();
    }
  }
}
