<nav class="bg-white border-b p-4 flex justify-between items-center">
    <div class="flex items-center gap-6 pl-2">
        <button (click)="onToggleSideNav()">
            <bars-3-outline-icon class="heroicon-sw-1.5"></bars-3-outline-icon>
        </button>
        <div class="flex items-center gap-2 text-2xl font-medium text-primary-700 font-poppins">
            <img class="w-10 h-10" src="/assets/images/logo.svg" alt="FightCloud Logo" />
            FightCloud
        </div>
    </div>
</nav>
