import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, concatMap, filter, iif, of, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthToken } from '../models/login-model';
import { el } from '@fullcalendar/core/internal-common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (request.headers.get('skip')) {
      request = request.clone({
        headers: request.headers.delete('skip')
      });
    } else {
      const token = this.authService.getToken();

      if (token && this.authenticateRoute(request)) {
        request = this.addTokenHeader(request, token);
      }
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !(request.url.includes('login') || request.url.includes('studentdetails')) && error.status === 401) {
        return this.handle401Error(request, next);
      }
      return throwError(() => error);
    }));
  }

  private authenticateRoute(request: HttpRequest<any>) {
    return !request.headers.has('skip');
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.getRefresh();

      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((token) => {
            this.isRefreshing = false;

            this.authService.setAuth(token);
            this.refreshTokenSubject.next(token.accessToken);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            
            this.authService.logout();
            return throwError(() => err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
