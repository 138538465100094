import { Component, OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { WindowService } from '../../services/window.service';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {
  isMobile: boolean = false;
  sideNavToggled: boolean = false;

  constructor(private windowService: WindowService) {}

  ngOnInit(): void {
    initFlowbite();
    this.windowService.isMobile.subscribe(isMobile => this.isMobile = isMobile);
  }

  onToggleSideNav() {
    this.sideNavToggled = !this.sideNavToggled;
  }

}
