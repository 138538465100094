<div class="pl-2 pt-2 h-full flex flex-col overflow-hidden" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
    <full-calendar class="flex-grow" #calendar [options]="calendarOptions" [events]="events$ | async">
        <ng-template #eventContent let-arg>
          <div class="h-full overflow-y-hidden touch-action">
            <div class="fc-event-title-container">
                <div class="fc-event-title fc-sticky">{{arg.event.title}}</div>
            </div>
            <div class="fc-event-time" [class.line-through]="isDisabled(arg.event.id)">{{arg.timeText}}</div>
          </div>
        </ng-template>
        <ng-template #dayHeaderContent let-arg>
          <div class="flex flex-col items-center">
            <span [class.text-gray-700]="!arg.isToday" [class.text-primary-700]="arg.isToday" class="text-xs font-normal mb-1">{{formatDateHeaderDay(arg.date)}}</span>
            <div *ngIf="arg.isToday" class="text-center mb-1 flex justify-center items-center w-10 h-10 rounded-full bg-primary-700">
              <span class="text-center text-2xl font-semibold text-white">{{formatDateHeaderDate(arg.date)}}</span>
            </div>
            <div *ngIf="!arg.isToday" class="h-10 w-10 flex justify-center items-center">
              <span class="text-2xl font-semibold  text-gray-700">{{formatDateHeaderDate(arg.date)}}</span>
            </div>
          </div>
        </ng-template>
        <ng-template #slotLabelContent let-arg>
          <div class="h-full flex flex-col">
            <div class="relative text-xs font-normal">{{arg.text}}</div>
          </div>
        </ng-template>
    </full-calendar>
</div>