import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StudentCalendar } from '../models/student-calendar.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient) { }

  getCalendar(startDate: string, endDate: string) {
    return this.http.post<StudentCalendar[]>(`${environment.baseUrl}/StudentSession/calendar`, { startDate, endDate });
  }
}
