import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  visible: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  setVisible(value: boolean) {
    this.visible.next(value);
  }
  
  toggle() {
    this.visible.next(!this.visible);
  }
}
