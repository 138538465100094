import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent {
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef<HTMLCanvasElement>;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvasEl.nativeElement.width = this.canvasEl.nativeElement.offsetWidth * ratio;
    this.canvasEl.nativeElement.height = this.canvasEl.nativeElement.offsetHeight * ratio;
    this.canvasEl.nativeElement.getContext("2d")?.scale(ratio, ratio);
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  change() {
    this.onChange.emit(this.signaturePad?.toDataURL());
  }

  clearPad() {
    this.signaturePad?.clear();
  }

  savePad() {
    const base64Data = this.signaturePad?.toDataURL();
  }
}
